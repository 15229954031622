import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function ProfitByMonth() {
  let history = useHistory();
  const { id } = useParams();
  const [loading,setloading] = useState(true);

  const [accessDenied,setAccess] = useState(true);
  

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  
  useEffect(() => {
     
  const data = {
    id:id
  }

   axios.post('/api/get-expense',data, {
    headers: { 'Allowed-Source': allowedSource }
})
.then(res=>{
    
    if(res.data.status===200){
       
        setInput({...input,
            name:res.data.name,
            amount:res.data.amount
         });

       setAccess(false);
    }
    else{
       
       // history.push('/');
    }
    setloading(false);
    setAccess(false);
})
.catch(error=>{
  
   // history.push('/admin');
}); 

    setloading(false)

    }, [history]);


    axios.interceptors.response.use(function(response){
      return response;
    },function (error){
     if(error.response.status === 403){//access denied
         swal("Forbidden",error.response.data.message,"warning");
         setAccess(true);
         history.push("/admin/dashbboard")
     }
      
    }
  
    );
   
   

    const updateExpense = (e)=>{
        e.preventDefault();
        if(input.name ==="" || input.amount <0)
          swal("Warning","Fill the fields correctly","warning")
        else{
            const data ={
                id:id,
                amount:input.amount,
                name:input.name
            }

            axios.post('/api/update-expense',data, {
                headers: { 'Allowed-Source': allowedSource }
            })
            .then(res=>{
                
                if(res.data.status===200){
                  
                   swal("Added","Successful",'success')
                   //window.location.reload();
                }
                else{
                   
                   // history.push('/');
                }
                setloading(false);
            
            })
            .catch(error=>{
              
               // history.push('/admin');
            }); 

        }
    }
 
  
    const [input, setInput] = useState({
        name:'',
        amount:0,
    
     });

    const handleInput = (e) =>{
        e.persist();
        if(e.target.type ==="number")
        setInput({...input,[e.target.name]:parseInt(e.target.value, 10)});
        else
        setInput({...input,[e.target.name]:e.target.value});
     }
 

if(loading){
  return(
    <div>Loading..</div>
  )
}
else if(accessDenied){
  return(
<div className='text-black'>Access Denied</div>
  )

}
else{
  return (
    //main div
    <div className='text-black d-flex flex-column w-75 m-auto'> 
    
    
     {/* Form Starts*/}
<form onSubmit={updateExpense} id='theForm'> 

<div class="mb-3">
  <label>Name</label>
  <input type="text" class="form-control" name='name' value={input.name} onChange={handleInput}/>
 
</div>

<div class="mb-3">
  <label>Amount</label>
  <input type="number" class="form-control" name='amount' value={input.amount} onChange={handleInput}/>
 
</div>



<button type="submit" className="btn btn-warning border-bottom border-top border-end border-1 border-black w-50 mx-auto my-4">Update</button>


</form>
{/* Form Ends*/}


              
    </div> //main div
  )
}


}
