import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";

export default function AddProduct() {
  let history = useHistory();
 
  const [productInput, setProduct] = useState({
     name:'',
     sku:'SKU',
    
     size_id:1,
     color_id:1,
     category_id:1,
     subcategory_id:1,
     subsubcategory_id:1,
     discount_id:1,

     stock:0,
     price:0,
     pic:"url",
     description:"This is description",
     errorList:[],
  });


  const [loading, setloading] = useState(true);
  const [errors, setErrors] = useState([]);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  useEffect(() => {
     
    axios.get('/api/collect-all',{
      headers: { 'Allowed-Source': allowedSource }
  })
    .then(res=>{
        
        if(res.data.status===200){
          setCategories(res.data.category);
          setSubCategories(res.data.subCategories);
          setSubSubCategories(res.data.subSubCategories);
          setSizes(res.data.sizes);
          setColors(res.data.colors);
          setDiscounts(res.data.discounts);
          
        }
        else{
            setErrors(res.data.messages);
            history.push('/admin/dashboard');
        }
        setloading(false);

    })
    .catch(error=>{
        //console.log(error);
        history.push('/admin/dashboard');
   });  



    }, [history]);


    const handleInput = (e) =>{
      e.persist();
      if(e.target.type ==="number")
      setProduct({...productInput,[e.target.name]:parseInt(e.target.value, 10)});
      else
      setProduct({...productInput,[e.target.name]:e.target.value});
   }

   const handleSelectChange = (event) => {
    console.log(event.target.value)
    setProduct({...productInput,[event.target.name]:parseInt(event.target.value, 10)});
  };




 const submitProduct = (e) =>{
   e.preventDefault();
 
  

   axios.post('/api/add-product',productInput,{
    headers: { 'Allowed-Source': allowedSource }
}).then(response=>{
     const res = response.data;

    if(res.status ===200){
        
        document.getElementById('theForm').reset();
       swal("Success",res.message,"success");
     
    }
    else if(res.status ===400){
       
        swal("Warning",res.message,"warning");
    }
    else{
      setProduct({...productInput,errorList:res.message});
    }

   })
   .catch(error=>{
    //console.log(error);
   });


 }



if(loading){
  return(
    <div>Loading..</div>
  )
}
else{
  return (
    //main div
    <div className='container-fluid px-4'> 
     <h1>Add Product</h1>


    {/* Form Starts*/}
<form onSubmit={submitProduct} id='theForm'> 

<div class="mb-3">
  <label>Name</label>
  <input type="text" class="form-control" name='name' value={productInput.name} onChange={handleInput}/>
 
</div>

<div>
<label>Category</label>
  <select class="form-select form-control mb-3" name='category_id' aria-label="Default select example"  onChange={handleSelectChange} >
  
      
        {
          categories.map((theCat,index)=>{

            return(
         
                <option key={index} value={theCat.id}>{theCat.name}</option>
       
            )
           
          })
        }

  </select>
  </div>

<div class="mb-3">
  <label>Price</label>
  <input type="number" class="form-control" name='price' value={productInput.price} onChange={handleInput}/>
</div>

<div class="mb-3">
  <label>Stock</label>
  <input type="number" class="form-control" name='stock' value={productInput.stock} onChange={handleInput}/>
 
</div>










<button type="submit" class="btn btn-primary">Add Product</button>


</form>
{/* Form Ends*/}



    </div> //main div
  )
}


}
