import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';


export default function UpdateProduct() {
  
  let history = useHistory();
  const { id } = useParams();
  const [loading, setloading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [product, setProduct] = useState({});


 const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);


  const [productInput, setProductInput] = useState({
     name:'',
     sku:'',
    
     size_id:1,
     color_id:1,
     category_id:1,
     subcategory_id:1,
     subsubcategory_id:1,
     discount_id:1,

     stock:0,
     price:0,
     pic:"url",
     description:"This is description",
     errorList:[],
  });



  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
    useEffect(() => {

      axios.post('/api/get-product-and-attributes',{ id: id },{
        headers: { 'Allowed-Source': allowedSource }
    })
      .then(res=>{
          
          if(res.data.status===200){
            setCategories(res.data.category);
            setSubCategories(res.data.subCategories);
            setSubSubCategories(res.data.subSubCategories);
            setSizes(res.data.sizes);
            setColors(res.data.colors);
            setDiscounts(res.data.discounts);

            setProductInput({...productInput,
              name:res.data.product.name,
              sku:res.data.product.sku,
              size_id:res.data.product.size_id,
              color_id:res.data.product.color_id,
              category_id:res.data.product.category_id,
              subcategory_id:res.data.product.subcategory_id,
              subsubcategory_id:res.data.product.subsubcategory_id,
              stock:res.data.product.stock,
              price:res.data.product.price,
              discount_id:res.data.product.discount_id,
              pic:res.data.product.pic,
              description:res.data.product.description,
            });
    
            
          }
          else{
         
          }
          setloading(false);
  
      })
      .catch(error=>{
          
     });  


   

  
      }, [history]);
      //console.log(productInput)


    const handleInput = (e) =>{
      e.persist();
      if(e.target.type ==="number")
      setProductInput({...productInput,[e.target.name]:parseInt(e.target.value, 10)});
      else
      setProductInput({...productInput,[e.target.name]:e.target.value});
   }



   const handleSelectChange = (event) => {
    setProductInput({...productInput,[event.target.name]:parseInt(event.target.value, 10)});
  };




 const submitProduct = (e) =>{
   e.preventDefault();
  const data = {
    id:id,
    productInput:productInput
  }

   axios.post('/api/update-product',data,{
    headers: { 'Allowed-Source': allowedSource }
})
.then(response=>{
     const res = response.data;
    if(res.status ===200){
       swal("Success",res.message,"success");
       history.push("/admin/view-products");
     
    }
    else if(res.status ===400){
      swal("Warning",res.message,"warning");
        setProduct({...productInput,errorList:res.message});
    }

   })
   .catch(error=>{
    //console.log(error);
   });


 }



if(loading){
  return(
    <div>Loading..</div>
  )
}
else{
  return (
    //main div
    <div className='container-fluid px-4'> 
     <h1>Update Product</h1>


  {/* Form Starts*/}
<form onSubmit={submitProduct} id='theForm'> 

  <div class="mb-3">
    <label>Name</label>
    <input type="text" class="form-control" name='name' value={productInput.name} onChange={(e)=>handleInput(e)}/>
   
  </div>
 
  <div>
<label>Category</label>
  <select class="form-select form-control mb-3" name='category_id' aria-label="Default select example"  onChange={handleSelectChange} >
  
      
        {
          categories.map((theCat,index)=>{

            return(
         
                <option key={index} value={theCat.id}>{theCat.name}</option>
       
            )
           
          })
        }

  </select>
  </div>
  

  <div class="mb-3">
    <label>Price</label>
    <input type="number" class="form-control" name='price' value={productInput.price} onChange={handleInput}/>
  </div>
  
  <div class="mb-3">
    <label>Stock</label>
    <input type="number" class="form-control" name='stock' value={productInput.stock} onChange={handleInput}/>
   
  </div>










  <button type="submit" class="btn btn-primary">Update</button>


</form>
 {/* Form Ends*/}



    </div> //main div
  )
}
}
