import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import b1 from '../../images/b1.jpg'
import b2 from '../../images/b2.jpg'
import loader from '../../images/loading.gif'
 
import bundles from '../../images/Bundles.png'
import treats from '../../images/Treats.png'
import waterbag from '../../images/Hot Water Bag.png'
import pads from '../../images/Pads.png'
import mask from '../../images/mask.png'

import s1 from '../../images/s1.jpg'
import s2 from '../../images/s2.jpg'
import s3 from '../../images/s3.jpg'
import s4 from '../../images/s4.jpg'
import s5 from '../../images/s5.jpg'
import s6 from '../../images/s6.jpg'

import axios from 'axios';
import swal from 'sweetalert';

import {Helmet} from 'react-helmet';
import ReactPixel from 'react-facebook-pixel';



export default function Home(props) {
  ReactPixel.pageView();

 
   const [loading, setloading] = useState(true);

   useEffect(() => {
     
    setloading(false);
  
   },[])

   
if(loading){
  return(
    <div >
      <img  src={loader}   alt="Your GIF"/> 
      
    </div>

  )
}
else{
  return (
    <div className='home-wrapper'>
            

           

           
             
              
    </div>
  )
}
  
}
