
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import {BrowserRouter as Router,Route,Switch,Redirect} from 'react-router-dom';
import { useEffect, useState } from 'react';

import NavBar from './components/frontend/NavBar';
import Home from './components/frontend/Home';
import Login from './components/frontend/auth/Login';
import Register from './components/frontend/auth/Register';
import axios from 'axios';
import swal from 'sweetalert';
import AdminPrivateRoute from './AdminPrivateRoute';


import {useHistory} from 'react-router-dom';

import Verify from './components/frontend/Verify';

//axios.defaults.baseURL = 'http://127.0.0.1:8000';
axios.defaults.baseURL = 'https://api.alamgirenterprise.com';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function(config){
 const token = localStorage.getItem('auth_token');
 config.headers.Authorization = token ? `Bearer ${token}` : '';
 return config;
});


function App() {
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  const history = useHistory();
const [loggedIn, setloggedIn] = useState(false);

const [cartLength, setCartLength] = useState(0);
const [isAdmin,setAdmin] = useState(false);

const handleLogout = (e)=>{
  e.preventDefault();
 

 const data = {};
  axios.post('/api/logout',data, {
    headers: { 'Allowed-Source': allowedSource }
}).then(res=>{
     
    if(res.data.status===200){
      setAdmin(false);
     
      localStorage.removeItem('auth_token');
      localStorage.removeItem('auth_name');
      localStorage.removeItem('auth');
     setloggedIn(false);
     swal("Logged out",res.data.message,'success');
  
     window.location.reload();
   
   }
  
  
  });
  

}

axios.interceptors.response.use(function(response){
  return response;
},function (error){
 if(error.response.status === 401){//Unauthorised
     
      
     history.push("/home")
 }
  
}

);

useEffect(() => {
  let admin = JSON.parse(localStorage.getItem("auth"));
  if(admin)
    setAdmin(true);

}, [])



  return (
  
    <Router>
    <div className="App ">
       
      

        

        

               <div className='innerContainer d-flex flex-column '>
               <NavBar isAdmin={isAdmin}  handleLogout={handleLogout} loggedIn={loggedIn} setloggedIn={setloggedIn} setCartLength={setCartLength}/> 
              <div className='contents'>
                <Switch>
                     
                       
                        <Route exact path="/home" >
                           <Home setCartLength={setCartLength}/> 
                        </Route>

                        <Route path='/login'>
                          {localStorage.getItem('auth_token') ? <Redirect to="/" />: <Login setloggedIn={setloggedIn} setAdmin={setAdmin} />}
                        </Route>

                        <Route path='/register'>
                          {localStorage.getItem('auth_token') ? <Redirect to="/" />: <Register setloggedIn={setloggedIn} />}
                        </Route>

                      
                      <Route exact path="/verify/:email/:token" >
                        <Verify/>
                      </Route>

                      
                      <Redirect exact from="/" to="/home"/>

                      <AdminPrivateRoute path="/admin" name="Admin" /> 

                </Switch>

                </div>
           

                </div>

                
             

    </div>

       
    </Router>
  
  );
}

export default App;
